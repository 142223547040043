import Axios from 'axios'

const axios = Axios.create()

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL


let axiosRequest = false

axios.interceptors.request.use(
  async function (config) {
    if (!axiosRequest && sessionStorage.getItem('accessToken')) {
      axiosRequest = true
      let res = await window.TokenService._watchToken()
      if (res) {
        return window.location.replace('/demo')
      }

      setTimeout(() => {
        axiosRequest = false
      }, 1000)
    }
    return config
  }
)

// 판매사이트 - 문의하기
function requestOrder(info) {
  let formdata = new FormData()
  formdata.append('jsonData', JSON.stringify(info))

  return axios({
    method: 'post',
    url: `operations/consulting`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: formdata
  })
}

// rsa키 요청
function chkRsaKey () {
  return axios({
    method: 'get',
    url: `auth/publickey`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export {
  requestOrder,
  chkRsaKey,
}