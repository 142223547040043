<template>
  <section class="about" id="about">
    <div class="inner">
      <div class="tabs">
        <button :class="{ on: aboutTab === 0 }" @click="setAboutTab(0)">
          NFT대행서비스
        </button>
        <button :class="{ on: aboutTab === 1 }" @click="setAboutTab(1)">
          위즈댑
        </button>
      </div>

      <div v-if="aboutTab === 0" class="tabCont">
        <h1>NFT대행서비스</h1>

        <p class="brief">
          고객님의 NFT 컨텐츠의 내용을 분석하고 협의하여 세계최대 규모의 OpenSea
          NFT마켓플레이스에 등록해드립니다.<br />또한, NFT 등록/발행/운영관리에
          대한 모든 것을 관리해드립니다.
        </p>

        <div class="floatBox">
          <h2>NFT대행서비스 특장점</h2>

          <ul class="list">
            <li>
              NFT와 블록체인 초보사업자분께 이해하기 쉽게 컨설팅 해드립니다.
            </li>
            <li>단 한번에 계약으로 NFT 등록/발행/운영까지 제공해드립니다.</li>
            <li>
              NFT관리자 페이지, NFT 홍보 페이지 등을 개발해드립니다(옵션사항)
            </li>
            <li>
              세계최대 규모의 NFT 마켓플레이스인 OpenSea를 통해 NFT를
              발행해드립니다.
            </li>
          </ul>
        </div>

        <h1>추천고객</h1>

        <p class="brief">
          개인이나 회사가 소유 중인 유니크한 컨텐츠를 NFT를 통하여 보다 안전한
          방법으로 판매를 하고자 하는 모든 분들께 자사 NFT대행 서비스를
          추천해드립니다.
        </p>

        <div class="floatBox">
          <h2>NFT대행서비스 추천 고객</h2>

          <ul class="imgList">
            <li>
              <figure>
                <img :src="require('@/assets/img/about/artist.png')" alt="" />
                <caption>
                  <p>아티스트</p>
                  <ul class="sublist">
                    <li>작곡/작사가</li>
                    <li>가수</li>
                    <li>화가</li>
                    <li>디지털아트</li>
                    <li>소설가</li>
                  </ul>
                </caption>
              </figure>
            </li>
            <li>
              <figure>
                <img :src="require('@/assets/img/about/designer.png')" alt="" />
                <caption>
                  <p>디자이너</p>
                  <ul class="sublist">
                    <li>의상디자이너</li>
                    <li>쥬얼리디자이너</li>
                    <li>그래픽스디자이너</li>
                    <li>컨텐츠디자이너</li>
                  </ul>
                </caption>
              </figure>
            </li>
            <li>
              <figure>
                <img
                  :src="require('@/assets/img/about/Influencer.png')"
                  alt=""
                />
                <caption>
                  <p>셀럽</p>
                  <ul class="sublist">
                    <li>수집가</li>
                    <li>연예인</li>
                    <li>인플루언서</li>
                    <li>스포츠선수</li>
                  </ul>
                </caption>
              </figure>
            </li>
            <li>
              <figure>
                <img
                  :src="require('@/assets/img/about/contents_corp.png')"
                  alt=""
                />
                <caption>
                  <p>컨텐츠 제작사</p>
                  <ul class="sublist">
                    <li>게임회사</li>
                    <li>영화사</li>
                    <li>엔터회사</li>
                    <li>스타트업</li>
                  </ul>
                </caption>
              </figure>
            </li>
          </ul>
        </div>

        <div class="floatBox">
          <h2>NFT대행서비스 수행절차</h2>

          <ul class="process">
            <li>
              <p class="subTitle"><span>상담신청 및 업체미팅</span></p>

              <ul class="sub">
                <li>컨텐츠분석</li>
                <li>요구사항분석</li>
                <li>이슈사항분석</li>
                <li>대행비용협의</li>
              </ul>
            </li>
            <li>
              <p class="subTitle"><span>계약체결</span></p>

              <ul class="sub">
                <li>계약서공유</li>
                <li>계약내용협의</li>
                <li>계약체결</li>
              </ul>
            </li>
            <li>
              <p class="subTitle">
                <span>OpenSea 컬렉션/NFT 등록</span>
              </p>

              <ul class="sub">
                <li>OpenSea 컬렉션 등록</li>
                <li>OpenSea NFT 등록</li>
                <li>NFT홍보사이트준비(옵션)</li>
              </ul>
            </li>
            <li>
              <p class="subTitle"><span>NFT발행/운영 시작</span></p>

              <ul class="sub">
                <li>NFT발행시작</li>
                <li>NFT운영관리시작</li>
                <li>추가개발배포(옵션)</li>
                <li>추가개발사항유지보수(옵션)</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="aboutTab === 1" class="tabCont right">
        <p class="subTitle">
          블록체인 메인넷 연동 / 사용자정의 스마트계약 개발 / UX/UI 개발을
          한번에
        </p>
        <h1>위즈댑 개발 및 구축 서비스</h1>

        <p class="brief">
          위즈댑은 이더리움 블록체인 네트워크 기반으로 고객님께서 추구하는
          유/무형의 가치상품에 대한 소유권 분배(WIZNFT), 크라우드
          펀딩(WIZCROWD), 소규모 NFT 마켓플레이스, NFT 게임아이템 등의 서비스를
          요구사항에 맞게 DApp를 개발 및 구축해드리고 있습니다. 또한,
          고객님께서는 비즈니스에만 집중하실 수 있도록 시스템운영/관리 서비스를
          제공해드립니다.
        </p>

        <div class="floatBox">
          <h2>위즈댑 개발/구축 서비스 특장점</h2>

          <ul class="list">
            <li>
              다양한 고객 니즈를 분석하여 요구사항에 맞는 스마트계약을
              개발해드립니다.
            </li>
            <li>
              공용 블록체인(이더리움) 배포부터 운영관리 서비스까지
              제공해드립니다.
            </li>
            <li>
              향후, 다양한 시스템 기능 업그레이드를 무료로 제공받으실 수
              있습니다.
            </li>
            <li>블록체인 및 다양한 솔루션 개발전문가들이 함께합니다.</li>
          </ul>
        </div>

        <div class="floatBox">
          <h2>위즈댑 시스템 전체 구성도 (NFT DApp용)</h2>

          <p class="briefText">
            이더리움 블록체인 시스템에서 작동가능한 NFT 스마트계약을
            개발해드립니다.<br />
            또한 스마트계약에 대한 기본정보 및 통계데이터를 관리할 수 있는
            서비스를 제공해드립니다.
          </p>

          <img
            :src="require('@/assets/img/about/system.png')"
            alt=""
            class="border"
          />
        </div>

        <div class="floatBox">
          <h2>위즈댑 패키지별 제공내역</h2>

          <ul class="pakage">
            <li>
              <p class="subTitle"><span>일반형</span></p>

              <ul class="sub">
                <li>사용자 요구사항분석 및 기획</li>
                <li>공용 블록체인(이더리움) 연동</li>
                <li>이더리움 지갑 연동</li>
                <li>비즈니스 서비스 개발</li>
                <li>데이터베이스 서비스 구축</li>
                <li>스마트계약(WIZCONT) 개발</li>
                <li>스마트계약 라이브러리 모듈 개발</li>
                <li>WIzDApp 공용 블록체인 배포</li>
                <li>관리자 웹서비스 개발</li>
                <li>사용자 서비스 개발</li>
              </ul>
            </li>

            <li class="premium">
              <p class="subTitle"><span>프리미엄형</span></p>

              <ul class="sub">
                <li>일반형</li>
                <li>전체 소스 제공</li>
                <li>개발환경 및 개발자교육 제공</li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="floatBox">
          <h2>위즈댑 개발 진행 절차</h2>

          <ul class="process">
            <li>
              <p class="subTitle"><span>상담신청 및 업체미팅</span></p>

              <ul class="sub">
                <li>서비스 설명</li>
                <li>요구사항분석</li>
                <li>이슈사항분석</li>
                <li>솔루션개발비용협의</li>
              </ul>
            </li>
            <li>
              <p class="subTitle"><span>계약체결</span></p>

              <ul class="sub">
                <li>계약서공유</li>
                <li>계약내용협의</li>
                <li>계약체결</li>
              </ul>
            </li>
            <li>
              <p class="subTitle">
                <span>위즈댑 개발 및 구축</span>
              </p>

              <ul class="sub">
                <li>블록체인네트워크 구축</li>
                <li>스마트계약 서비스 개발</li>
                <li>패키지별 추가구성 개발</li>
              </ul>
            </li>
            <li>
              <p class="subTitle"><span>통합테스트 및 배포</span></p>

              <ul class="sub">
                <li>베타버전 통합테스트 수행</li>
                <li>알파버전 통합테스트 수행</li>
                <li>전체서비스 운영 배포</li>
                <li>유지보수 시작</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState(['aboutTab'])
  },

  methods: {
    ...mapMutations({
      setAboutTab: 'setAboutTab'
    })
  }
}
</script>

<style lang="scss" scoped>
.about {
  min-height: 100vh;
  padding: 100px 0;
  box-sizing: border-box;

  .inner {
    max-width: 1300px;
    margin: 0 auto;

    .tabs {
      display: flex;
      margin-bottom: 80px;
      // padding: 0 80px;
      border-bottom: 1px solid #ccc;
      justify-content: center;

      button {
        flex: 1 1 auto;
        padding: 20px 0;
        background: #eee;
        font-size: 20px;
        font-weight: 500;
        transition: all 0.4s ease;

        &.on {
          color: #fff;
          background: #0050ff;
        }

        &:first-child {
          border-radius: 6px 0 0 0;
        }
        &:last-child {
          border-radius: 0 6px 0 0;
        }
      }
    }

    .tabCont {
      & > .subTitle {
        font-size: 24px;
        color: #0050ff;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
      }

      h1 {
        margin: 20px 0 50px 0;
        font-size: 60px;
        font-weight: 700;
        line-height: 80px;
        text-align: center;
      }
      .floatBox + h1 {
        margin-top: 120px;
      }

      .brief {
        padding: 0 200px;
        font-size: 19px;
        color: #333;
        line-height: 2;
        text-align: center;
        word-break: keep-all;
      }

      .imgList {
        display: flex;
        padding-top: 40px;
        border-top: 1px solid #ddd;
        justify-content: space-between;

        li {
          flex: 0 1 20%;

          figure {
            margin: 0;

            img {
              width: 100%;
            }
            caption {
              display: block;
              width: 100%;
              p {
                margin-top: 30px;
                font-size: 18px;
              }

              .sublist {
                margin-top: 10px;
                padding: 20px 30px;
                border-radius: 10px;
                background: #efefef;

                li {
                  margin-top: 20px;
                  font-size: 16px;

                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
            }
          }
        }
      }

      .briefList {
        width: 650px;
        margin: 0 auto 40px;
        li {
          font-size: 18px;
          color: #333;
          line-height: 2;

          &:before {
            content: '- ';
            font-weight: 900;
          }
        }
      }

      .briefText {
        margin: 0 0 40px;
        font-size: 18px;
        line-height: 2;
        text-align: center;
      }

      .pakage {
        display: flex;
        justify-content: space-around;
        text-align: center;
        flex-wrap: wrap;

        & > li {
          display: flex;
          flex-direction: column;
          flex: 0 0 auto;
          padding: 0 0 20px 0;
          border-radius: 10px 10px 0 0;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

          .subTitle {
            flex: 0 0 auto;
            padding: 20px 0;
            font-size: 26px;
            font-weight: 700;
            line-height: 1;
            border-radius: 10px 10px 0 0;
            background: rgba(255, 192, 0, 0.4);
          }

          .sub {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 80px;
            margin-top: 20px;

            li {
              margin: 10px 0;
              font-size: 16px;

              &:before {
                content: '-';
                margin-right: 5px;
              }
            }
          }

          &.premium {
            .subTitle {
              background: rgba(0, 80, 255, 0.8);
              color: #fff;
            }

            .sub {
              margin-top: 0;
              padding: 0 80px;

              li {
                font-weight: 700;
                font-size: 20px;

                &:before {
                  content: '+';
                  display: block;
                  margin-bottom: 16px;
                  font-size: 22px;
                  font-weight: 500;
                }

                &:first-child:before {
                  content: '';
                }
              }
            }
          }
        }
      }

      .floatBox {
        width: 100%;
        margin: 80px 0;
        padding: 80px 100px;
        box-shadow: 2.2px 2px 24px 0 rgb(108 154 255 / 22%);
        box-sizing: border-box;

        h2 {
          margin-bottom: 50px;
          font-size: 30px;
          font-weight: 700;
          line-height: 70px;
          text-align: center;
        }

        .list {
          border-top: 1px solid #000;

          li {
            padding: 40px 20px;
            font-size: 18px;
            border-top: 1px solid #ddd;
            color: #333;

            &:first-child {
              border-top: none;
            }
          }
        }

        .border {
          display: block;
          width: 70%;
          margin: 0 auto;
          padding: 20px 0;
          border: 1px solid #ddd;
        }

        .process {
          display: flex;
          flex-wrap: wrap;

          & > li {
            flex: 1 1 25%;
            padding: 0 10px;
            box-sizing: border-box;

            .subTitle {
              position: relative;
              width: 80%;
              margin-left: 10%;
              padding-top: 80%;
              border-radius: 50%;
              opacity: 0.8;

              span {
                position: absolute;
                top: 50%;
                width: 100%;
                padding: 0 10px;
                color: #fff;
                font-size: 24px;
                word-break: keep-all;
                box-sizing: border-box;
                transform: translateY(-50%);
                text-align: center;
              }
            }
            &:nth-child(1) .subTitle {
              animation: scale 10s ease-in-out forwards infinite;
              background: #000;
            }
            &:nth-child(2) .subTitle {
              animation: scale 10s 2s ease-in-out forwards infinite;
              background: #0050ff;
            }
            &:nth-child(3) .subTitle {
              animation: scale 10s 4s ease-in-out forwards infinite;
              background: #ffc000;
            }
            &:nth-child(4) .subTitle {
              animation: scale 10s 6s ease-in-out forwards infinite;
              background: #595959;
            }

            .sub {
              margin-top: 40px;
              padding: 20px 10px 0 30px;
              border-top: 1px solid #ddd;
              font-size: 16px;
              line-height: 2;

              li {
                &:before {
                  content: '';
                  position: relative;
                  top: -4px;
                  display: inline-block;
                  width: 4px;
                  height: 4px;
                  margin-right: 5px;
                  background: #0050ff;
                }
              }
            }
          }
        }
      }
    }
  }
}

// 모바일
@media screen and (max-width: 1023px) {
  .about {
    min-height: auto;

    .inner {
      padding: 0 12px;

      .tabCont {
        & > .subTitle {
          padding: 0 12px;
          font-size: 20px;
          word-break: keep-all;
        }

        h1 {
          font-size: 26px;
        }
        .brief {
          padding: 0;
          font-size: 16px;
          text-align: left;
        }

        .imgList {
          display: flex;
          flex-direction: column;
          padding-top: 0;
          border: none;

          li {
            flex: 1 1 auto;
            margin-top: 40px;

            &:first-child {
              margin-top: 0;
            }

            figure {
              display: flex;
              flex-direction: row;
              align-items: flex-start;

              img {
                flex: 0 0 80px;
                width: 80px;
              }
              caption {
                flex: 1 1 auto;
                margin-left: 15px;
                text-align: left;

                p {
                  margin-top: 0;
                }

                .sublist {
                  li {
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }

        .briefText {
          font-size: 16px;
          text-align: left;
        }

        .pakage {
          & > li {
            flex: 1 1 auto;
            margin: 20px 0;
            border-radius: 0;
            padding: 0;
            box-shadow: none;
            border: 1px solid #ccc;

            .subTitle {
              padding: 15px 0;
              font-size: 18px;
              border-radius: 0;
            }

            .sub {
              padding: 0;
              margin-bottom: 20px;
            }

            &.premium {
              .sub {
                padding: 0;
              }
            }
          }
        }

        .floatBox {
          margin-top: 50px;
          padding: 30px 15px;
          h2 {
            font-size: 20px;
            margin-bottom: 30px;
            line-height: 1.7;
          }

          .list {
            li {
              padding: 25px 15px;
              line-height: 1.7;
              word-break: keep-all;
            }
          }

          .border {
            width: 100%;
            padding: 0 15px;
            box-sizing: border-box;
          }

          .process {
            flex-direction: column;
            & > li {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              margin-top: 70px;
              padding: 0;

              &:first-child {
                margin-top: 0;
              }

              &:nth-child(2) {
                margin-top: 42px;
              }

              &:after {
                content: '';
                position: absolute;
                left: 32px;
                top: 120px;
                display: block;
                width: 36px;
                height: 21px;
                background: url('../../assets/img/about/arrow_blue.png') 50% 50% /
                  100% auto no-repeat;
              }

              &:last-child {
                &:after {
                  display: none;
                }
              }

              &:nth-child(1):after {
                animation: arrow 10s 1s ease-out infinite;
              }
              &:nth-child(2):after {
                animation: arrow 10s 3s ease-out infinite;
              }
              &:nth-child(3):after {
                animation: arrow 10s 5s ease-out infinite;
              }

              .subTitle {
                flex: 0 0 100px;
                width: 100px;
                padding-top: 100px;
                margin-left: 0;
                span {
                  font-size: 16px;
                  line-height: 1.4;
                }
              }

              &:nth-child(3) {
                .subTitle {
                  span {
                    font-size: 15px;
                  }
                }
              }

              .sub {
                flex: 1 1 auto;
                margin: 0 0 0 20px;
                padding: 0;
                border-top: none;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  10% {
    transform: scale(1.1);
    opacity: 1;
  }
  20%,
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

@keyframes arrow {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  10%,
  15% {
    opacity: 0;
    transform: translateY(30px);
  }

  15.01% {
    opacity: 0;
    transform: translateY(-15px);
  }
  25% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>