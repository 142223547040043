<template>
  <section id="home" class="home">
    <ChainUpVisual />

    <AboutDapp />

    <ChainUpEngineer />

    <Reference />

    <QuestionModal />
  </section>
</template>

<script>
import ChainUpVisual from '@/components/home/chainUpVisual.vue'
import AboutDapp from '@/components/home/aboutDapp.vue'
import ChainUpEngineer from '@/components/home/chainUpEngineer.vue'
import Reference from '@/components/common/reference.vue'
import QuestionModal from '@/components/common/modal/question.vue'

export default {
  name: 'Page-Home',

  components: {
    ChainUpVisual,
    AboutDapp,
    ChainUpEngineer,
    Reference,
    QuestionModal
  }
}
</script>

<style lang="scss">
.home {
}
</style>
