<template>
  <section id="engineer" class="engineer">
    <div class="inner">
      <h1>엔지니어 소개</h1>

      <ul>
        <li class="personal">
          <p class="grade">대표이사</p>

          <p class="years">개발경력 17년차</p>

          <p class="language">
            <span>개발언어</span>C, C++, JAVA, Solidity, C#, Android, Python,
            ACE, Netty, Spring, .Net
          </p>

          <ul class="career">
            <li class="history">블록체인기반 생활플랫폼 설계 및 개발/운영</li>
            <li class="history">클라우드 서비스 플랫폼 설계 및 개발/운영</li>
            <li class="history">
              라이브방송 커머스 서비스 기획/설계 및 개발/운영
            </li>
            <li class="history">P2P 웹서비스 서버/클라이언트 개발/운영</li>
            <li class="history">
              위치기반 O2O대리운전 서비스 기획/설계 및 개발/운영
            </li>
          </ul>
        </li>

        <li class="personal">
          <p class="grade">기술이사</p>

          <p class="years">개발경력 11년차</p>

          <p class="language">
            <span>개발언어</span>C, C++, JAVA, Python, Node.js, Anroid, Netty,
            Spring
          </p>

          <ul class="career">
            <li class="history">
              SK텔링크 안심번호 통합서버 설계 및 개발/운영
            </li>
            <li class="history">라이브방송 커머스 서비스 설계 및 개발/운영</li>
            <li class="history">쇼핑몰 광고서비스앱 설계 및 개발/운영</li>
            <li class="history">
              실시간 쇼핑몰 대용량이미지 자동 크롭서비스 설계 및 개발
            </li>
          </ul>
        </li>

        <li class="personal">
          <p class="grade">개발팀장</p>

          <p class="years">개발경력 9년차</p>

          <p class="language">
            <span>개발언어</span>Vue.js, Nuxt, Javascript, css, html5,
            bootstrap, ngnix, hybrid app
          </p>

          <ul class="career">
            <li class="history">코인 중개거래소 웹사이트 개발/운영</li>
            <li class="history">라이브방송 커머스 서비스 개발/운영</li>
            <li class="history">위치기반 주차이용서비스 개발/운영</li>
            <li class="history">실시간 전국 도로교통조회 서비스 개발/운영</li>
          </ul>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.engineer {
  padding: 150px 0;
  background: url('../../assets/img/about/bg_engineer.jpg') 50% 50% / cover
    no-repeat;
  box-sizing: border-box;

  .inner {
    max-width: 1300px;
    margin: 0 auto;

    h1 {
      color: #fff;
      font-size: 60px;
      font-weight: 700;
      line-height: 80px;
      text-align: center;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }

    & > ul {
      display: flex;
      margin-top: 80px;
      justify-content: space-around;
      flex-wrap: wrap;

      .personal {
        flex: 0 1 30%;
        padding: 30px 50px 50px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 6px;
        box-sizing: border-box;
        color: #fff;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.6);

        .grade {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }

        .years {
          margin: 10px 0 40px;
          font-size: 16px;
          text-align: center;
        }

        .language {
          font-size: 16px;
          text-align: center;

          span {
            display: block;
            font-weight: 700;
            margin-bottom: 10px;
          }
        }

        .career {
          margin-top: 50px;
          font-size: 14px;

          .history {
            margin-top: 20px;
            word-break: keep-all;
            line-height: 1.6;

            &:before {
              content: '-';
              padding-right: 7px;
              font-size: 16px;
              font-weight: 900;
              color: #0050ff;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .engineer {
    padding: 50px 0;

    .inner {
      h1 {
        font-size: 30px;
        line-height: 1;
      }

      & > ul {
        margin-top: 50px;
        padding: 0 15px;
        flex-direction: column;

        .personal {
          margin-top: 30px;
          padding: 20px;
        }
      }
    }
  }
}
</style>