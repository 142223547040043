<template>
  <div class="visual">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide>
        <div class="inner">
          <p class="right">NFT대행서비스로<br />NFT 등록/발행/운영을 한번에</p>
        </div>

        <img :src="require('@/assets/img/visual/visual_01.jpg')" alt="" />
      </swiper-slide>

      <swiper-slide>
        <div class="inner">
          <p class="right">블록체인 기반 스마트계약<br />솔루션 개발</p>
        </div>

        <img :src="require('@/assets/img/visual/visual_02.jpg')" alt="" />
      </swiper-slide>

      <swiper-slide>
        <div class="inner">
          <p class="center">위자드소프트와 함께 성공적인 비즈니스를</p>
        </div>

        <img :src="require('@/assets/img/visual/visual_03.jpg')" alt="" />
      </swiper-slide>
    </swiper>

    <div class="navi">
      <button class="prev" @click="swiperObj.slidePrev()"></button>
      <button class="next" @click="swiperObj.slideNext()"></button>
    </div>

    <div class="pagination">
      <button
        class="pager"
        :class="{ active: activeIdx === 0 }"
        @click="slide(1)"
      >
        01
      </button>
      <button
        class="pager"
        :class="{ active: activeIdx === 1 }"
        @click="slide(2)"
      >
        02
      </button>
      <button
        class="pager"
        :class="{ active: activeIdx === 2 }"
        @click="slide(3)"
      >
        03
      </button>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

export default {
  components: {
    Swiper,
    SwiperSlide
  },

  directives: {
    swiper: directive
  },

  data() {
    return {
      activeIdx: 0,

      swiperOptions: {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        speed: 800,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        on: {
          init: () => {
            let next = null
            next = setInterval(() => {
              if (!this.swiperObj) return clearInterval(next)

              this.swiperObj.slideNext(800)
            }, 6000)
          }
        }
      },
      swiper: null
    }
  },

  computed: {
    swiperObj() {
      if (!this.$refs.mySwiper) return false
      return this.$refs.mySwiper.$swiper
    }
  },

  mounted() {
    this.swiperInit()
  },

  methods: {
    swiperInit() {
      this.swiper = new Swiper('.swiper', this.swiperOptions)

      this.swiperObj.on('slideChange', () => {
        this.activeIdx = this.swiperObj.realIndex
      })
    },

    swiperPrev() {
      this.swiperObj.slidePrev(800)
      this.activeIdx = this.swiperObj.realIndex
    },

    swiperNext() {
      this.swiperObj.slideNext(800)
      this.activeIdx = this.swiperObj.realIndex
    },

    slide(idx) {
      this.swiperObj.slideTo(idx)
    }
  }
}
</script>

<style lang="scss" scoped>
.visual {
  position: relative;
  height: 70vh;

  .swiper-container {
    height: 100%;

    &::v-deep .swiper-button-next,
    &::v-deep .swiper-button-prev {
      color: rgba(255, 255, 255, 0.6);
    }
    &::v-deep .swiper-button-next {
      right: 40px;
    }
    &::v-deep .swiper-button-prev {
      left: 40px;
    }

    &::v-deep .swiper-pagination {
      text-align: right;
      margin-bottom: 80px;
      padding-right: 20%;
      box-sizing: border-box;

      .swiper-pagination-bullet {
        position: relative;
        width: auto;
        height: auto;
        margin: 0 20px;
        background: transparent;
        color: #fff;
        font-size: 16px;
        transition: all 0.8s ease;
        vertical-align: bottom;
        opacity: 0.6;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -3px;
          display: block;
          width: 100%;
          height: 2px;
          background: #0050ff;
          opacity: 0;
          transition: opacity 0.8s ease;
        }

        &.swiper-pagination-bullet-active {
          font-weight: 800;
          opacity: 1;
          transform: scale(1.6);

          &:after {
            opacity: 1;
          }
        }
      }
    }

    .swiper-slide {
      overflow: hidden;
      position: relative;
      background-color: #000;

      .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 1300px;

        p {
          color: #fff;
          font-size: 60px;
          font-weight: 700;
          line-height: 1.7;
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          transform: translateX(100px);
          opacity: 0;
          transition: all 1s 0.8s ease;

          &.right {
            text-align: right;
          }

          &.center {
            text-align: center;
          }
        }
      }
      &.swiper-slide-active {
        .inner {
          p {
            transform: translateX(0);
            opacity: 1;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .navi {
    button {
      position: absolute;
      top: calc(50% - 20px);
      z-index: 2;
      width: 20px;
      height: 40px;
      font-size: 30px;
      font-weight: 700;
      color: #fff;
      opacity: 0.7;
      transition: opacity 0.4s ease;

      &.prev {
        left: 5%;
        background: url('../../assets/img/common/right_arrow_white.png') 50% 50% /
          100% auto no-repeat;
        transform: rotate(180deg);
      }
      &.next {
        right: 5%;
        background: url('../../assets/img/common/right_arrow_white.png') 50% 50% /
          100% auto no-repeat;
      }
      &:hover {
        opacity: 1;
      }
    }
  }

  .pagination {
    position: absolute;
    right: 20%;
    bottom: 80px;
    z-index: 2;
    text-align: right;
    box-sizing: border-box;

    .pager {
      position: relative;
      width: auto;
      height: auto;
      margin: 0 20px;
      background: transparent;
      color: #fff;
      font-size: 16px;
      transition: all 0.8s ease;
      vertical-align: bottom;
      opacity: 0.6;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -3px;
        display: block;
        width: 100%;
        height: 2px;
        background: #0050ff;
        opacity: 0;
        transition: opacity 0.8s ease;
      }

      &.active {
        font-weight: 800;
        opacity: 1;
        transform: scale(1.6);

        &:after {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .visual {
    height: 70vh;

    .swiper-container {
      &::v-deep .swiper-button-next {
        display: none;
      }
      &::v-deep .swiper-button-prev {
        display: none;
      }

      .swiper-slide {
        .inner {
          p {
            padding: 0 16px;
            word-break: keep-all;
            font-size: 30px;
            line-height: 2;
          }
        }
      }
    }

    .navi {
      display: none;
    }
    .pagination {
      right: 20px;
      bottom: 40px;
    }
  }
}
</style>