<template>
  <section
    class="questionPop"
    v-if="csFormOpen"
    @click.self="close"
    :class="{ reqing: req }"
  >
    <div class="diasbleLayer"></div>
    <div class="formBox" ref="form" :class="{ ovh: privacyPopOpen }">
      <button class="close" @click="close">
        <img
          :src="require('@/assets/img/common/close_bk.png')"
          alt="팝업닫기"
        />
      </button>
      <h3>연락을 드립니다</h3>
      <p>
        <b>서비스 내용, 수수료, 월비용</b> 등 상세내용은 상담을 통해
        알려드립니다.<br />아래 연락처를 남겨주시면, 이메일과 전화상담으로
        도와드리겠습니다.
      </p>

      <form class="form">
        <div class="radioBox">
          <input
            type="radio"
            id="serviceType3"
            name="serviceType"
            :value="3"
            v-model.number="form.serviceType"
            checked
          />
          <label for="serviceType3">NFT 대행서비스 상담</label>
          <input
            type="radio"
            id="serviceType2"
            name="serviceType"
            :value="2"
            v-model.number="form.serviceType"
          />
          <label for="serviceType2">위즈댑 상담</label>
        </div>

        <input
          type="text"
          class="corpNameInput"
          ref="firstFocus"
          v-model="form.corpName"
          placeholder="업체명을 입력해 주세요."
          required
          @keydown.enter.prevent="next('nameInput')"
        />
        <input
          type="text"
          class="nameInput"
          v-model="form.name"
          placeholder="성함을 입력해 주세요."
          ref="name"
          required
          @keydown.enter.prevent="next('mailInput')"
        />
        <input
          type="email"
          class="mailInput"
          v-model="form.email"
          placeholder="이메일을 입력해 주세요."
          ref="email"
          required
          @keydown.enter.prevent="next('phoneInput')"
        />
        <input
          type="text"
          class="phoneInput"
          v-model="form.mobile"
          placeholder="전화번호를 입력해 주세요."
          ref="phone"
          required
          @input="validPhoneNumber"
          @keydown.enter.prevent="next('msgInput')"
        />
        <textarea
          v-model="form.reqMsg"
          class="msgInput"
          ref="msg"
          placeholder="문의내용을 입력해 주세요."
        ></textarea>
      </form>

      <div class="agreeBox">
        <input type="checkbox" v-model="agree" id="agree" name="agree" />
        <label for="agree" ref="agree"
          ><span>개인정보 수집 및 이용에 동의합니다.</span></label
        >
        <button @click="termView">약관보기</button>
      </div>

      <button class="submit" @click="submit" :disabled="req">
        {{ req ? '신청중입니다.' : '상담신청' }}
      </button>

      <div class="term" v-if="privacyPopOpen">
        <button class="termClose" @click="termClose">
          <img
            :src="require('@/assets/img/common/close_bk.png')"
            alt="팝업닫기"
          />
        </button>

        <h4>개인정보 수집 및 이용에 대한 안내</h4>

        <p>
          온라인 개인정보 수집 및 이용에 대한 안내<br />개인정보의 수집 및
          이용목적, 수집하는 개인정보의 항목, 개인정보의 보유 및 이용기간을 안내
          드립니다.
        </p>

        <ol>
          <li>
            <h5>수집 및 이용 목적</h5>
            <ol>
              <li>상담 접수 및 응대</li>
            </ol>
          </li>

          <li>
            <h5>수집 항목</h5>
            <ol>
              <li>성함</li>
              <li>전화번호</li>
              <li>이메일</li>
            </ol>
          </li>

          <li>
            <h5>보유 및 이용 기간</h5>
            <ol>
              <li>
                관련 법령 또는 회사 내부방침에 의해 보존 필요 시까지, 그 외 이용
                목적 달성 후 지체없이 파기<br />미동의 시 불이익 사항
              </li>
            </ol>
          </li>
        </ol>

        <p>
          귀하는 위 개인정보 수집 및 이용을 거부할 수 있으나, 동의를 거부하실
          경우 상담 및 소개서를 받아보실 수 없습니다. 귀하의 상담 내용은 원활한
          상담을 위하여 보관되며, 홈페이지에 게시된 개인정보처리방침에 따라
          처리됩니다.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { requestOrder } from '@/api'

export default {
  name: 'questionPop',

  data() {
    return {
      form: {
        serviceType: 3,
        corpName: null,
        name: null,
        email: null,
        mobile: null,
        reqMsg: null
      },
      agree: false,
      req: false
    }
  },

  computed: {
    ...mapState(['csFormOpen', 'privacyPopOpen'])
  },

  watch: {
    csFormOpen: {
      handler(n) {
        if (n)
          setTimeout(() => {
            this.$refs.firstFocus.focus()
          }, 0)
      }
    }
  },

  methods: {
    ...mapMutations({
      setCsFormOpen: 'setCsFormOpen',
      setPrivacyPopOpen: 'setPrivacyPopOpen'
    }),

    next(target) {
      document.querySelector('.' + target).focus()
    },

    close() {
      this.setCsFormOpen(false)
      this.form.corpName = null
      this.form.name = null
      this.form.email = null
      this.form.mobile = null
      this.form.reqMsg = null
      this.agree = false
    },

    termView() {
      this.$refs.form.scrollTop = 0
      this.setPrivacyPopOpen(true)
    },

    termClose() {
      this.setPrivacyPopOpen(false)
    },

    validPhoneNumber(e) {
      const val = e.target.value
      const number = /[^0123456789-]/g
      if (number.test(val)) {
        this.form.mobile = val.substring(0, val.length - 1)
      }
    },

    valid() {
      if (!this.agree) {
        alert('개인정보 수집 및 이용에 동의 해야 합니다.')
        return false
      }

      if (!this.form.corpName) {
        alert('업체명을 입력해 주세요.')
        this.$refs.firstFocus.focus()
        return false
      }

      if (!this.form.name) {
        alert('이름을 입력해 주세요.')
        this.$refs.name.focus()
        return false
      }

      if (!this.form.email) {
        alert('이메일을 입력해 주세요.')
        this.$refs.email.focus()
        return false
      }

      if (!this.form.mobile) {
        alert('전화번호를 입력해 주세요.')
        this.$refs.phone.focus()
        return false
      }
      if (this.form.mobile?.length < 8) {
        alert('전화번호는 최소 9자리 이상이어야 합니다.')
        this.$refs.phone.focus()
        return false
      }

      if (!this.form.reqMsg) {
        alert('내용을 입력해 주세요.')
        this.$refs.msg.focus()
        return false
      }

      return true
    },

    async submit() {
      let valid = this.valid()
      if (!valid) return false
      this.req = true

      try {
        let res = await requestOrder(this.form)
        if (res.data.jsonData.resultCode === '0001') {
          alert('상담신청이 정상적으로 접수되었습니다.')
          this.close()
          this.req = false
        } else {
          console.log(res)
          alert('상담신청에 실패했습니다.')
          this.close()
          this.req = false
        }
      } catch (err) {
        console.log(err)
        alert('상담신청에 실패했습니다.')
        this.close()
        this.req = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.questionPop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  &.reqing .diasbleLayer {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: 94%;
    max-width: 580px;
    height: 90%;
    max-height: 800px;
    background: rgba(0, 0, 0, 0.2);
    transform: translate(-50%, -50%);
    cursor: wait;
  }

  .formBox {
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 94%;
    max-width: 580px;
    height: 90%;
    max-height: 800px;
    padding: 30px;
    background: #fff;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    box-sizing: border-box;

    &.ovh {
      overflow: hidden;
    }

    .close {
      position: absolute;
      top: 32px;
      right: 32px;
    }

    h3 {
      margin-top: 30px;
      font-size: 24px;
      text-align: center;
      color: #262626;
    }
    p {
      margin: 30px 0 0;
      padding: 0 25px;
      color: #262626;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;

      b {
        font-weight: 400;
      }
    }

    .form {
      margin-top: 15px;
      input,
      textarea {
        display: block;
        width: 100%;
        height: 54px;
        margin: 10px 0;
        padding: 10px;
        border: none;
        background: #f8f9fb;
        border-radius: 2.5px;
        border: 1px solid #f8f9fb;
        box-sizing: border-box;
        font-family: inherit;
        font-weight: 300;
        letter-spacing: 1px;

        &:focus {
          background: #fff;
          border: 1px solid #0050ff;
          outline: none;
        }
      }

      textarea {
        height: 146px;
        resize: none;
      }

      .radioBox {
        margin-top: 30px;
        input {
          display: none;

          &:checked + label {
            background: #0050ff;
            color: #fff;
          }
        }

        label {
          display: inline-block;
          height: 50px;
          margin: 0 10px;
          padding: 0 20px;
          background: #eee;
          border-radius: 6px;
          font-size: 16px;
          vertical-align: middle;
          line-height: 50px;
          cursor: pointer;

          &:nth-of-type(1) {
            margin-left: 0;
          }
        }
      }
    }

    .agreeBox {
      input {
        display: none;
      }
      label {
        display: inline-block;
        cursor: pointer;
        font-size: 0;
        vertical-align: middle;

        &:before {
          content: '';
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 5px;
          vertical-align: middle;
          box-sizing: border-box;
          border-radius: 2.5px;
          border: 1px solid #ccc;
          vertical-align: middle;
        }

        span {
          display: inline-block;
          margin-left: 8px;
          color: #262626;
          font-size: 14px;
          font-weight: 400;
          vertical-align: middle;
        }
      }
      input:checked + label {
        &:before {
          content: '';
          background-color: #0050ff;
          background-image: url('../../../assets/img/common/chkbox.png');
          background-position: 50% 50%;
          background-size: 12px auto;
          background-repeat: no-repeat;
          font-size: 14px;
          color: #fff;
          border: 1px solid #0050ff;
        }
      }

      button {
        margin-left: 10px;
        text-decoration: underline;
        vertical-align: middle;
      }
    }

    .submit {
      display: block;
      width: 166px;
      height: 48px;
      margin: 25px auto 0;
      border-radius: 24px;
      font-size: 18px;
      background: #0050ff;
      color: #fff;
    }

    .term {
      overflow-y: auto;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 110;
      width: 100%;
      height: 100%;
      padding: 30px;
      background: #fff;
      box-sizing: border-box;

      .termClose {
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 30px;
        font-weight: 700;
      }

      h4 {
        font-size: 20px;
      }

      p {
        margin: 20px 0;
        padding: 0;
        font-size: 14px;
      }

      ol {
        font-size: 14px;

        & > li {
          margin: 10px 0 10px 15px;
          list-style-type: decimal;

          h5 {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 500;
          }

          ol > li {
            margin-left: 14px;
            font-weight: 400;
            list-style-type: lower-roman;
          }
        }
      }
    }
  }
}

// 모바일
@media screen and (max-width: 1023px) {
  .questionPop {
    .formBox {
      p {
        padding: 0;
      }

      .agreeBox {
        button {
          margin-left: 0;
          margin-top: 10px;
        }
      }

      .form {
        .radioBox {
          label {
            margin: 0 5px;
            padding: 0 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>