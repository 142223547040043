<template>
  <section class="reference">
    <div class="inner">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <a
            href="https://opensea.io/"
            target="_blank"
            title="https://opensea.io/"
          >
            <img
              :src="require('@/assets/img/logos/1_logo_opensea.png')"
              alt=""
            />
          </a>
        </swiper-slide>
        <swiper-slide>
          <a
            href="https://ethereum.org/en/"
            target="_blank"
            title="https://ethereum.org/en/"
          >
            <img
              :src="require('@/assets/img/logos/2_logo_ethereum.png')"
              alt=""
            />
          </a>
        </swiper-slide>
        <swiper-slide>
          <a
            href="https://www.klaytn.com/"
            target="_blank"
            title="https://www.klaytn.com/"
          >
            <img
              :src="require('@/assets/img/logos/3_logo_klaytn.png')"
              alt=""
            />
          </a>
        </swiper-slide>
        <swiper-slide>
          <a
            href="https://metamask.io/"
            target="_blank"
            title="https://metamask.io/"
          >
            <img
              :src="require('@/assets/img/logos/4_logo_metamask.png')"
              alt=""
            />
          </a>
        </swiper-slide>
        <swiper-slide>
          <a
            href="https://upbit.com/home"
            target="_blank"
            title="https://upbit.com/home"
          >
            <img :src="require('@/assets/img/logos/5_logo_upbit.png')" alt="" />
          </a>
        </swiper-slide>
        <swiper-slide>
          <a
            href="https://coinone.co.kr/"
            target="_blank"
            title="https://coinone.co.kr/"
          >
            <img
              :src="require('@/assets/img/logos/6_logo_coninone.png')"
              alt=""
            />
          </a>
        </swiper-slide>
        <swiper-slide>
          <a
            href="https://www.binance.com/en"
            target="_blank"
            title="https://www.binance.com/en"
          >
            <img
              :src="require('@/assets/img/logos/7_logo_binance.png')"
              alt=""
            />
          </a>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

export default {
  name: 'ReferenceLogos',
  components: {
    Swiper,
    SwiperSlide
  },

  directives: {
    swiper: directive
  },

  data() {
    return {
      activeIdx: 0,

      swiperOptions: {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 20,
        loop: true,
        speed: 400,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        breakpoints: {
          1023: {
            slidesPerView: 3,
            spaceBetween: 120
          }
        },
        on: {
          init: () => {
            let next = null
            next = setInterval(() => {
              if (!this.swiperObj) return clearInterval(next)

              this.swiperObj.slideNext(800)
            }, 6000)
          }
        }
      },
      swiper: null
    }
  },

  computed: {
    swiperObj() {
      if (!this.$refs.mySwiper) return false
      return this.$refs.mySwiper.$swiper
    }
  },

  mounted() {
    this.swiperInit()
  },

  methods: {
    swiperInit() {
      this.swiper = new Swiper('.swiper', this.swiperOptions)

      this.swiperObj.on('slideChange', () => {
        this.activeIdx = this.swiperObj.realIndex
      })
    },

    swiperPrev() {
      this.swiperObj.slidePrev(800)
      this.activeIdx = this.swiperObj.realIndex
    },

    swiperNext() {
      this.swiperObj.slideNext(800)
      this.activeIdx = this.swiperObj.realIndex
    },

    slide(idx) {
      this.swiperObj.slideTo(idx)
    }
  }
}
</script>

<style lang="scss" scoped>
.reference {
  overflow: hidden;
  padding: 40px 0;

  .inner {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 80px;
    box-sizing: border-box;

    .swiper-container {
      overflow: visible;

      &::v-deep .swiper-slide {
        opacity: 0.2;
        transition: opacity 0.4s ease;
        a {
          display: block;
          pointer-events: none;
        }

        img {
          width: 100%;
        }

        &.swiper-slide-active,
        &.swiper-slide-next,
        &.swiper-slide-prev {
          opacity: 1;
        }
        &.swiper-slide-active,
        &.swiper-slide-next,
        &.swiper-slide-prev {
          a {
            pointer-events: all;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .reference {
    padding: 10px 0;
    .inner {
      padding: 0 40px;

      .swiper-container {
        overflow: hidden;

        &::v-deep .swiper-slide {
          &.swiper-slide-next,
          &.swiper-slide-prev {
            opacity: 0.3;
          }
        }
      }
    }
  }
}
</style>
